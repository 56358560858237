import React, { useState, useRef, useEffect, useCallback } from 'react';
import f_logo_v from '@/assets/images/business/control_ball_img/wave.png';
import f_logo_v2 from '@/assets/images/business/control_ball_img/wave.png';
import f_logo_v3 from '@/assets/images/business/control_ball_img/wave.png';
import f_logo_r from '@/assets/images/business/control_ball_img/rotation.png';
import f_logo_p from '@/assets/images/business/control_ball_img/contraction.png';
import f_logo_s from '@/assets/images/business/control_ball_img/suck.png';
import f_logo_f from '@/assets/images/business/control_ball_img/fingering.png';
import f_logo_t from '@/assets/images/business/control_ball_img/thrusting.png';
import f_logo_d from '@/assets/images/business/control_ball_img/depth.png';
import f_logo_pos from '@/assets/images/business/control_ball_img/position.png';
import f_logo_o from '@/assets/images/business/control_ball_img/oscillate.png';
import './index.scss';
import { orderUtils } from '@/utils/order-utils.js';
import { updateOrderLine } from '@/redux/action/order-line.js';
import { updateControlState } from '@/redux/action/control-state.js';
import { updateCurrentBallInfo } from '@/redux/action/current-ball-info.js';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';
import SocketEvent from '@/serve/socket-event';

const SlideBtn = (props) => {
    let logoImg = f_logo_v;
    let btnId = "toy1-drag-v-btn-id";
    let {
      data,
      orderLine,updateOrderLine,
      controlState,updateControlState,
      currentBallInfo,updateCurrentBallInfo,
    } = props;

    let { linkId,toyId,orderType,isEnd,socketServiceInstanct, ballCount, ballIndex, ballRadius, zIndex, setZIndex, toyName } = data;
    let ballImgClass = 'ball-img-pink'; //滑球样式

    //根据玩具、马达，计算滑球按钮的domId
    let toyIndexFlag = toyId;
    switch (orderType) {
      case 'v1':
        logoImg = f_logo_v;
        btnId = toyIndexFlag + "-drag-v1-btn-id"
        ballImgClass = 'ball-img-pink'
        break
      case 's':
        logoImg = f_logo_s;
        btnId = toyIndexFlag + "-drag-s-btn-id"
        ballImgClass = 'ball-img-pink'
        break
      case 'v':
        logoImg = f_logo_v
        btnId = toyIndexFlag + "-drag-v-btn-id"
        ballImgClass = 'ball-img-pink'
        break
      case 'v2':
        logoImg = f_logo_v2
        btnId = toyIndexFlag + "-drag-v2-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      case 'v3':
        logoImg = f_logo_v3
        btnId = toyIndexFlag + "-drag-v3-btn-id"
        ballImgClass = 'ball-img-purple'
        break
      case 'r':
        logoImg = f_logo_r
        btnId = toyIndexFlag + "-drag-r-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      case 'p':
        logoImg = f_logo_p
        btnId = toyIndexFlag + "-drag-p-btn-id"
        ballImgClass = 'ball-img-purple'
        break
      case 'f':
        logoImg = f_logo_f
        btnId = toyIndexFlag + "-drag-f-btn-id"
        ballImgClass = 'ball-img-purple'
        break
      case 't':
        logoImg = f_logo_t
        btnId = toyIndexFlag + "-drag-t-btn-id"
        ballImgClass = 'ball-img-pink'
        break
      case 'd':
        logoImg = f_logo_d
        btnId = toyIndexFlag + "-drag-d-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      case 'pos':
        logoImg = f_logo_pos
        btnId = toyIndexFlag + "-drag-pos-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      case 'o':
        logoImg = f_logo_o
        btnId = toyIndexFlag + "-drag-o-btn-id"
        ballImgClass = toyName === 'Gush 2' ? 'w40' : 'w40 ball-img-blue'
        break
      default:
        logoImg = f_logo_v
        btnId = toyIndexFlag + "-drag-v-btn-id"
        ballImgClass = 'ball-img-pink'
        break
    }
    const [prevY, setPrevY] = useState(0);
    const [prevBtnTop, setPrevBtnTop] = useState(0);
    const [touchBtnId, setTouchBtnId] = useState("");

    const padlr = 16
    // let defaultTop = 220;
    let defaultTop = 300 - padlr *2 - ballRadius * 2;

    const [slideBtnTop, setSlideBtnTop] = useState(defaultTop);
    const [slideBtnParentWidth, setSlideBtnParentWidth] = useState((360 - (padlr * 2)) / ballCount);// const control_page_left_ele = document.querySelector(".pc-toy-control") -> 360
    const [slideBtnTranslateX, setSlideBtnTranslateX] = useState(0);
    const [drawTimer, setDrawTimer] = useState(null);
    const [ballShadowClass, setBallShadowClass] = useState(""); //滑球样式
    const [stopToys, setStopToys] = useState(false);
    const [defaultDrawTimer, setDefaultDrawTimer] = useState(null);
    const [lastOrder, setLastOrder] = useState("");
    const [isMouseDown, setIsMouseDown] = useState(false); //是否按下鼠标

    useEffect(()=>{
      setSlideBtnParentWidth((document.querySelector(".pc-balls-area").clientWidth - (padlr * 2)) / ballCount)
    }, [ballCount]);

    //发给玩具指令
    const sendOrderToToy = useCallback((orderLine,linkId)=>{
      let toyData = {...orderLine};
      let orderBody = {};
      Object.keys(toyData).forEach(function(key){
        const curToy = toyData[key]
        let ctId = curToy.toyId;
        orderBody[ctId] = { v: -1, v1: -1, v2: -1, v3: -1, s: -1, p: -1, r: -1, f: -1, t: -1, d: -1, o: -1, pos: -1 };

        let toyFunArr = curToy.toyFun.split(',');
        toyFunArr.forEach(order => {
          let level = -1;
          let lineArr = []
          switch (order) {
            case 'v':
            case 'v1':
            case 's':
              lineArr = curToy.line1
              break
            case 'v2':
            case 'r':
            case 'p':
            case 'f':
            case 'd':
            case 'pos':
            case 'o':
              lineArr = curToy.line2
              break
            case 't':
              if(curToy.type.toLowerCase()==='solace') {
                lineArr = curToy.line1
              }else{
                lineArr = curToy.line2
              }
              break
            case 'v3':
              lineArr = curToy.line3
              break
            default:
              lineArr = curToy.line1
              break
          }
          if(lineArr.length > 1){
              level = lineArr[lineArr.length - 1];
          }
          if (order === 'p') {
              if(level >=1 && level <= 6){
                  level = 1;
              }else if(level > 6 && level < 14){
                  level = 2;
              }else if(level >= 14){
                  level = 3;
              }
          }
          if (order === 'pos' && level > 0) {
            level *= 5;
          }
          orderBody[ctId] = { ...orderBody[ctId], [order]: level }
        })
        // console.log(`--orderBody: `, JSON.stringify(orderBody[ctId]));
      });
      let cate = { "version": 5, "cate": "id", "id": orderBody };
      let order = {
          "toyCommandJson": JSON.stringify(cate),
          "linkId": linkId,
          "userTouch": isMouseDown ? true : false
      }
      return order;
    },[isMouseDown])

    //防抖函数引用
    const throttleSaveLevel = useRef(throttle((value, orderLineTmp, linkId, mouseDown)=>{
      addPointToLine(value, orderLineTmp, linkId, mouseDown);
    }, 100)).current;
    //节流方法
    const addPointToLine = useCallback((value, orderLineTmp, linkId, mouseDown=false)=>{
      let level = orderUtils.heightToLevel(value, defaultTop, 0);
      // console.log(`--level: `, level);
      // console.log(`--value: `, value);
      setSlideBtnTop(value);
      let tmpArr = [...orderLineTmp];
      for(let i=0; i<tmpArr.length; i++){
        let func = tmpArr[i].toyFun.split(",");
        if(tmpArr[i].toyId === toyId){
          if (['v', 'v1', 's'].includes(orderType) || (tmpArr[i].type.toLowerCase()==='solace' && orderType==='t' )) {
            tmpArr[i].line1.push(level)
            if (func.length > 1) {
              let length = tmpArr[i].line2.length
              let n = tmpArr[i].line2[length-1]
              tmpArr[i].line2.push(n)
              if(tmpArr[i].line3) {
                let length = tmpArr[i].line3.length
                let n = tmpArr[i].line3[length-1]
                tmpArr[i].line3.push(n)
              }
            }
          } else if(['v2', 'r', 'p', 'f', 't', 'd', 'o', 'pos'].includes(orderType)){
            tmpArr[i].line2.push(level)
            if (func.length > 1) {
              let length = tmpArr[i].line1.length
              let n = tmpArr[i].line1[length-1]
              tmpArr[i].line1.push(n)
              if(tmpArr[i].line3) {
                let length = tmpArr[i].line3.length
                let n = tmpArr[i].line3[length-1]
                tmpArr[i].line3.push(n);
              }
            }
          }else if(['v3'].includes(orderType)){
            tmpArr[i].line3.push(level)
            if (func.length > 1) {
              let length = tmpArr[i].line1.length
              let n = tmpArr[i].line1[length-1]
              tmpArr[i].line1.push(n);
              {
                let length = tmpArr[i].line2.length
                let n = tmpArr[i].line2[length-1]
                tmpArr[i].line2.push(n)
              }
            }
          }
        }else{ //自动补点其他玩具曲线数据，保持曲线x轴长度一致
          Object.keys(tmpArr[i]).forEach(key => {
            if (key.includes('line')) {
              let length = tmpArr[i][key].length
              let n = tmpArr[i][key][length-1]
              tmpArr[i][key].push(n)
            }
          })
        }
        updateOrderLine(tmpArr);
      }
      if(orderLine && linkId){
        let order = sendOrderToToy(orderLine,linkId);
        order.userTouch = mouseDown ? true : false;
        let orderStr = JSON.stringify(order);
        if(lastOrder !== orderStr){
          let lastSendTime = localStorage.getItem("last-send-timestamp");
          let now = new Date().getTime();
          if(lastSendTime){
            if(now - lastSendTime >= 90){
              socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order);
              setLastOrder(orderStr);
              localStorage.setItem("last-send-timestamp", now);
            }
          }else{
            socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order);
            setLastOrder(orderStr);
            localStorage.setItem("last-send-timestamp", now);
          }
        }
      }
    },[orderType,toyId,defaultTop,updateOrderLine,lastOrder,orderLine,sendOrderToToy,socketServiceInstanct]);
    //点击控制面板，改变选中的控制球的level
    useEffect(()=>{
      let targetEl = document.getElementById("pc-balls-area");
      const clickBallsArea = (e) => {
        if(btnId === currentBallInfo.currentSelBallId){
          let y = targetEl.offsetTop;
          let height = e.clientY - y - ballRadius;
          if(height < 0){
            height = 0;
          }else if(height > defaultTop){
            height = defaultTop;
          }
          setSlideBtnTop(height);
          throttleSaveLevel(parseInt(height), orderLine, linkId, true);
        }
      }
      if(targetEl){
        targetEl.addEventListener('click',clickBallsArea, false);
      }
      //事件解绑
      return function(){
        if(targetEl){
          targetEl.removeEventListener('click',clickBallsArea, false);
        }
      }
    }, [defaultTop,btnId,currentBallInfo,orderLine,throttleSaveLevel,linkId, ballRadius]);

    //在控制面板和画线区域滚动鼠标滚轮/按键盘↑↓，改变选中的控制球的level
    useEffect(()=>{
      let targetEl = document.getElementById("pc-toy-control");
      const changeLevel = (offset) => {
          let height = 9999;
          if(offset > 0){
            height =  slideBtnTop+offset <= defaultTop ? slideBtnTop+offset : defaultTop;
          }else if(offset < 0){
            height =  slideBtnTop+offset >= 0 ? slideBtnTop+offset : 0;
          }
          if(height !== 9999){
            setSlideBtnTop(height);
            throttleSaveLevel(parseInt(height), orderLine, linkId, true);

            //玩具控制状态 0未开始，1控制中 2已失效
            if(controlState.state === 0){
              updateControlState({"state":1});
              //查询controlLink信息，同步数据
              if(socketServiceInstanct){
                let json1 = {"linkId": linkId};
                setTimeout(()=>{
                  socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, json1);
                },200);
              }
            }
          }
      }
      const toyControlKeyUp = (e) => {
        if(btnId !== currentBallInfo.currentSelBallId) return
        const whiteList = [38, 40]
        if(!whiteList.includes(e.keyCode)) return
        e.preventDefault()
        let offset = 40;
        if(e.keyCode === 38) offset = -40
        changeLevel(offset)
      }
      const toyControlAreaMouseWheel = (e) => {
        if(btnId !== currentBallInfo.currentSelBallId) return
        e.preventDefault()
        let offset = 40;
        if(e.deltaY){ // 常规浏览器
            if(e.deltaY < 0) offset = -40
        }else if(e.detail){ // Firefox浏览器
            if(e.detail < 0) offset = -40
        }
        changeLevel(offset)
      }
      if(targetEl){
        targetEl.addEventListener('mousewheel',toyControlAreaMouseWheel, false);
        targetEl.addEventListener('DOMMouseScroll',toyControlAreaMouseWheel, false); //兼容Firefox
        document.addEventListener('keyup',toyControlKeyUp, false);
      }
      //事件解绑
      return function(){
        if(targetEl){
          targetEl.removeEventListener('mousewheel',toyControlAreaMouseWheel, false);
          targetEl.removeEventListener('DOMMouseScroll',toyControlAreaMouseWheel, false);
          document.removeEventListener('keyup',toyControlKeyUp, false);
        }
      }
    }, [linkId,defaultTop,btnId,slideBtnTop,currentBallInfo,controlState,updateControlState,sendOrderToToy,
      orderLine,lastOrder,socketServiceInstanct,throttleSaveLevel]);

    //进入页面就画一根直线水平播放播放
    useEffect(()=>{
      let tm = setTimeout(()=>{
        if(isEnd.end){
          clearTimeout(tm);
          setDefaultDrawTimer(null);
        }else{
          addPointToLine(parseInt(slideBtnTop), orderLine, linkId);
          setDefaultDrawTimer(tm);
        }
      },100);
      return function(){
        clearTimeout(tm);
        setDefaultDrawTimer(null);
      }
    },[isEnd,slideBtnTop,orderLine,addPointToLine,linkId]);

    //计算选择的滑球外发光样式
    useEffect(()=>{
      let classStr = "";
      if(btnId === currentBallInfo.currentSelBallId){
        if (['v', 'v1', 's', 't'].includes(orderType) || toyName === 'Gush 2') {
          classStr = 'ball-shadow-pink'
        } else if (['f', 'v3', 'p'].includes(orderType)) {
          classStr = 'ball-shadow-purple'
        } else {
          classStr = 'ball-shadow-blue'
        }
      }
      setBallShadowClass(classStr);
    },[btnId, currentBallInfo, orderType, toyName]);

    //鼠标控制
    useEffect(()=>{
      const mouseDownEvent2 = ()=>{
        // let pcBallsArea = document.getElementById("pc-balls-area");
        // let imgArr = pcBallsArea.getElementsByTagName("img");
        // for(let i=0; i<imgArr.length; i++){
        //     imgArr[i].removeAttribute("class");
        // }
        setIsMouseDown(true);
      }
      //手指离开控制球，需开启定时器自动补点画线
      const mouseLeaveEvent2 = ()=>{
        setTimeout(() => {
            setIsMouseDown(false);
        })
      }
      //事件绑定
      if(btnId){
        let element = document.getElementById(btnId);
        if(element){
          element.addEventListener('mousedown',mouseDownEvent2, false);
          // let pcBallsAreaEl = document.getElementById("pc-balls-area");
          // pcBallsAreaEl.addEventListener('mouseleave',mouseLeaveEvent2, false);
          window.document.addEventListener('mouseup',mouseLeaveEvent2, false);
        }
      }
      //事件解绑
      return function(){
        if(btnId){
          let element = document.getElementById(btnId);
          if(element){
            element.removeEventListener('mousedown',mouseDownEvent2, false);
            // let pcBallsAreaEl = document.getElementById("pc-balls-area");
            // pcBallsAreaEl.removeEventListener('mouseleave',mouseLeaveEvent2, false);
            window.document.removeEventListener('mouseup',mouseLeaveEvent2, false);
          }
        }
      }
    },[btnId]);

    useEffect(()=>{
      //触碰滑动球则开始控制
      const mouseDownEvent = (e)=>{
        const newZIndex = Array(ballCount)
        newZIndex[ballIndex] = 2
        setZIndex(newZIndex)
        let pcBallsArea = document.getElementById("pc-balls-area");
        let imgArr = pcBallsArea.getElementsByTagName("img");
        for(let i=0; i<imgArr.length; i++){
            imgArr[i].removeAttribute("class");
        }
        e.preventDefault();
        let eId = e.currentTarget.id;
        if(eId){
          //清除自动补点画线定时器
          clearInterval(drawTimer);
          setDrawTimer(null);
          //玩具控制状态 0未开始，1控制中 2已失效
          if(controlState.state === 0){
            updateControlState({"state":1});
            //查询controlLink信息，同步数据
            if(socketServiceInstanct){
              let json1 = {"linkId": linkId};
              setTimeout(()=>{
                socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, json1);
              },200);
            }
          }
          setTouchBtnId(eId);
          setPrevY(e.screenY);
          let tEl = document.getElementById(eId);
          if(tEl){
            setPrevBtnTop(tEl.offsetTop - ballRadius);
          }
        }
        updateCurrentBallInfo({...currentBallInfo, "currentSelBallId": btnId});
      }
      //滑动转换为指令等级
      const mouseMoveEvent = (e)=>{
        e.preventDefault();
        if(isMouseDown){
          const page_ele = document.querySelector(".pc-page")
          const translateX = e.x - page_ele.offsetLeft - (slideBtnParentWidth / 2) - padlr - (ballIndex * slideBtnParentWidth)
          // setSlideBtnTranslateX(translateX)
          const xMin = -(slideBtnParentWidth / 2 - ballRadius + (slideBtnParentWidth * ballIndex))
          // console.log(`--xMin: `, xMin);
          const xMax = slideBtnParentWidth / 2 - ballRadius + (slideBtnParentWidth * (ballCount - 1 - ballIndex))
          // console.log(`--xMax: `, xMax);
          setSlideBtnTranslateX(translateX > xMax ? xMax : translateX < xMin ? xMin : translateX)
          let clientY = e.screenY;
          let changeY = prevY - clientY - ballRadius;
          let newTop = prevBtnTop - changeY;
          if(newTop<=0){
            newTop = 0;
          }else if(newTop >= defaultTop){
            newTop = defaultTop;
          }
          setDefaultDrawTimer(null);
          clearInterval(defaultDrawTimer);
          if(newTop === defaultTop){ //停止指令不能被节流方法过滤掉
            if(!stopToys){
              setStopToys(true);
              addPointToLine(parseInt(defaultTop), orderLine, linkId);
              let tm = setTimeout(()=>{
                addPointToLine(parseInt(defaultTop), orderLine, linkId);
                setStopToys(false);
                clearTimeout(tm);
              },100);
            }
          }else{
            setSlideBtnTop(newTop);
            let mouseDown = true;
            throttleSaveLevel(parseInt(newTop), orderLine,linkId,mouseDown);
          }
        }
      };
      //鼠标离开控制球，需开启定时器自动补点画线
      const mouseLeaveEvent0 = ()=>{
        clearInterval(drawTimer);
        if(controlState.state === 2){  //结束控制
          socketServiceInstanct.disconnect();
        }
        //为避免最后滑动的指令被防抖过滤掉
        //这里在手指touchend的时候不经过防抖方法补发最后一个指令
        if(orderLine && linkId){
          let order = sendOrderToToy(orderLine,linkId);
          let orderStr = JSON.stringify(order);
          if(lastOrder !== orderStr){
            socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order);
            setLastOrder(orderStr);
          }
        }
      }
      //事件绑定
      if(btnId){
        let element = document.getElementById(btnId);
        if(element){
          element.addEventListener('mousedown',mouseDownEvent, false);
          let pcBallsAreaEl = document.getElementById("pc-balls-area");
          pcBallsAreaEl.addEventListener('mousemove',mouseMoveEvent, false);
          pcBallsAreaEl.addEventListener('mouseleave',mouseLeaveEvent0, false);
          pcBallsAreaEl.addEventListener('mouseup',mouseLeaveEvent0, false);
        }
      }
      //事件和定时器 解绑
      return function(){
        if(btnId){
          let element = document.getElementById(btnId);
          if(element){
            element.removeEventListener('mousedown',mouseDownEvent, false);
            let pcBallsAreaEl = document.getElementById("pc-balls-area");
            pcBallsAreaEl.removeEventListener('mousemove',mouseMoveEvent, false);
            pcBallsAreaEl.removeEventListener('mouseleave',mouseLeaveEvent0, false);
            pcBallsAreaEl.removeEventListener('mouseup',mouseLeaveEvent0, false);
          }
        }
        clearInterval(drawTimer);
        setDrawTimer(null);
      }

    },[btnId,touchBtnId,addPointToLine,controlState,defaultTop,stopToys,isMouseDown,
      ballIndex, ballCount, zIndex, setZIndex, slideBtnParentWidth, ballRadius,
      drawTimer,orderLine,prevBtnTop,prevY,throttleSaveLevel,linkId,defaultDrawTimer,slideBtnTop,
      lastOrder,updateControlState,updateOrderLine,socketServiceInstanct,sendOrderToToy,
      currentBallInfo,updateCurrentBallInfo]);

    return (
        <>
            <div className="ball-div" style={{ zIndex: zIndex[ballIndex] || 1 }}>
                <div id={btnId} draggable="true" className="drag-btn" style={{marginTop:slideBtnTop+"px", transform: `translateX(${slideBtnTranslateX}px)` }}>
                    {/* <div className="ball-line"></div> */}
                    {
                      <div className={ `drag-btn-box ${ballImgClass} ${ballShadowClass}` } >
                        <img src={logoImg} alt=""/>
                        <div className={`toyname ${toyName.length >= 9 && 'line2'}`}>{toyName}</div>
                      </div>
                    }
                </div>
            </div>
        </>
    );
}

export default connect(state => ({
    orderLine: state.orderLine,
    controlState: state.controlState,
    currentBallInfo: state.currentBallInfo,
}), {
  updateOrderLine,
  updateControlState,
  updateCurrentBallInfo
})(SlideBtn);
