import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import { useRef, useEffect, useMemo } from "react";
import drawCurve from '@/utils/draw-line.js';
import toysArr from "@/utils/toy.js";
import { useTranslation } from 'react-i18next'
import { isGush2 } from '@/utils/toy.js';

const OrderLine = (props) => {
    const { t } = useTranslation();
    let { toyStatus, orderLine } = props;
    // console.log(`--toyStatus, orderLine: `, toyStatus, orderLine);
    const boardCanvasArr = useRef([[],[]])
    const setCanvasRefs = (i, j, el) => {
      if(boardCanvasArr.current[i][j] || !el) return
      boardCanvasArr.current[i][j] = el
    }
    const lineColor = useMemo(() => ['#FF2D89', '#0C9EF2', '#DE1EDB'], [])
    let lineArr = [];
    if(toyStatus && toyStatus.length > 0){
      for(let r = 0; r < toyStatus.length; r++){
        let olJson = toyStatus[r];
        let toyType = toysArr[(olJson.type+"").toLowerCase()] || olJson.type
        let toyName = toyType + (olJson.version?(" " + olJson.version):"");
        let statusStr = "";
        if((olJson.status + "") === "false"){
          statusStr = (<span style={{ color:"#FF3E3E" }}> ({t('toy_reconnecting')})</span>)
        }

        let str = <div className="line-item" key={`line-area-${r+1}`}>
          <div className="toy-name">{toyName}{statusStr}</div>
          {
            [2, 1, 0].map(i => {
              return <canvas key={`cvs-line${r}${i+1}`} className="board-canvas" style={{ width: "100%" }} ref={el=>setCanvasRefs(r,i,el)}></canvas>
            })
          }
        </div>
        lineArr.push(str)
      }
    }
    useEffect(() => {
      let clientWidth = document.body.clientWidth;
      if(clientWidth > 540){
        clientWidth = 540;
      }
      let maxPointNum = parseInt(clientWidth/5);
      // console.log(`--orderLine: `, JSON.parse(JSON.stringify(orderLine)));
      if (orderLine && orderLine.length) {
        for (let i = 0; i < orderLine.length; i++) {
          if (boardCanvasArr.current[i][0]) {
            const { clientWidth: width, clientHeight: height } = boardCanvasArr.current[i][0]
            boardCanvasArr.current[i].forEach(item => {
              item.width = width
              item.height = height
            })
            let lineObj = orderLine[i]
            Object.keys(lineObj).forEach(function(key) {
              if (key.includes('line')) { // line1, line2, line3
                let idx = key.slice(-1) * 1  // 1, 2, 3
                let lineArr = lineObj[key]
                if (lineArr.length > 1) {
                  if (lineArr.length > maxPointNum) { //截取最后maxPointNum个点画线(刚好一屏)
                    lineArr = lineArr.slice(lineArr.length - maxPointNum)
                  }
                  let pts = handleLineData(lineArr, width, height)
                  const ctx = boardCanvasArr.current[i][idx - 1].getContext("2d")
                  ctx.clearRect(0, 0, width, height)
                  let lineWidth = 1.4
                  let smoothLevel = 0
                  let color = lineColor[idx - 1]
                  if (idx === 2) {
                    if (lineObj.toyFun.includes('f') || lineObj.toyFun === 'p') { // f指令线条为紫色
                      color = lineColor[2]
                    } else if (lineObj.toyFun.includes('d') || lineObj.toyFun.includes('pos') || (lineObj.toyFun === 'o' && !isGush2(lineObj))) { // d指令线条为蓝色
                      color = lineColor[1]
                    } else if (lineObj.toyFun.includes('t') || isGush2(lineObj)) { // t指令线条为粉色
                      color = lineColor[0]
                    }
                  }
                  drawCurve(ctx, pts, smoothLevel, color, lineWidth, true, false)
                }
              }
            })
          }
        }
      }
    }, [lineColor, orderLine]);

    return (
        <>
          <div className="order-lines">
          {
            lineArr
          }
          </div>
        </>
    );
}
//生成曲线x、y坐标
function handleLineData(lineArr, width, height){
  let xNum = parseFloat(width/height).toFixed(2)
  let arr = [];
  for(let i=0; i<lineArr.length; i++){
    let x = i*xNum;
    let y = 20 - (lineArr[i]*(xNum/2) - parseInt(height/(xNum/2.8)));
    arr.push(x, y);
  }
  return arr;
}

export default connect(state => ({orderLine:state.orderLine}), {})(OrderLine);
